@import 'src/styles/variables';

$fontColor: $white;

.layoutContainer {
  background: linear-gradient(180deg, $purple500 1.31%, #221930 100%) !important;
}

.layoutContent * {
  color: $fontColor;
}

// HEADER
.header {
  color: $fontColor;
}

.logo {
  path {
    fill: $white;
  }
}

.lovePhrase {
  color: $fontColor !important;
}

// FORM NAVIGATION
.formNavigation * {
  color: $fontColor !important;
}

// FORM CONTAINER
.formContainer {
  background-color: rgba(255, 255, 255, 0.12) !important;
  border: 0;
}

// INPUT
.inputLabel {
  color: $fontColor !important;
}

.input {
  background-color: $purple300 !important;
}

.button {
  background-color: $white !important;
  color: $purple500 !important;
}

// COMMON QUESTIONS
.commonQuestions {
  .questions {
    background-color: $purple500;
    border: 0;
  }

  .selectArrow {
    path {
      stroke: $white;
    }
  }
}

// FOOTER
.needHelp * {
  color: $fontColor !important;
}

.chatIcon {
  path {
    fill: $white;
  }

  circle {
    fill: $purple500;
  }
}

.copyright {
  color: $fontColor;
}
